import { BodyViewModel } from '../body-view-model/bodyViewModel';

export class BodyViewModelBuilder {
  viewModel: BodyViewModel = {
    isBookOnlineAllowed: true,
  };
  withBookOnlineAllowed(isBookOnlineAllowed: boolean) {
    this.viewModel.isBookOnlineAllowed = isBookOnlineAllowed;
    return this;
  }

  withMessage(message: string) {
    this.viewModel.message = message;
    return this;
  }

  build() {
    return this.viewModel;
  }
}
