import {
  ControllerParams,
  CreateControllerFn,
} from 'yoshi-flow-editor-runtime';
// import { ITEM_TYPES } from '@wix/advanced-seo-utils/editor';
import settingsParams from './settingsParams';
import HttpClient from 'yoshi-serverless-client';
import { getConfig } from '../../api/config.api';
import {
  dummyViewModelFactory,
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../service-page-view-model/servicePageViewModel';
import { getSettingsValues } from '@wix/tpa-settings';
import { biDefaults, generateWidgetDefaults } from './bi/consts';
import {
  dummySchedulingViewModel,
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
  schedulingSectionViewModelFactory,
} from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { getServiceSchedulingData } from './controller-logic/scheduling-fetcher';

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const errorReporter = appData?.ravenReporter || (() => null);
  const { setProps } = flowAPI.controllerConfig;

  const isMobile = flowAPI.isMobile();
  const isRTL = flowAPI.isRTL();

  return {
    async pageReady() {
      const publicData =
        flowAPI.controllerConfig.config.publicData.COMPONENT || {};
      const settings = getSettingsValues(publicData, settingsParams);
      const instance = flowAPI.controllerConfig.appParams.instance as string;
      const experiments = await flowAPI.getExperiments();
      const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
      const isPreview = wixCodeApi.window.viewMode === 'Preview';
      const t = flowAPI.translations.t;
      const loggerDefaults = flowAPI.biLogger?.util.updateDefaults({
        ...biDefaults,
        ...generateWidgetDefaults(
          flowAPI.controllerConfig.appParams,
          flowAPI.controllerConfig.platformAPIs,
        ),
      });

      let scheduleViewModel: SchedulingSectionViewModel = {
        status: SchedulingSectionStatus.LOADING,
      };
      let viewModel: ServicePageViewModel,
        navigateToCalendar = () => {};
      if (!flowAPI.inEditor || isPreview) {
        const httpClient = new HttpClient({ isSSR: flowAPI.isSSR() });
        const servicePageIndex = wixCodeApi.location.path.indexOf(
          'booking-service-page',
        );
        const isServiceSlugExistsInPath =
          servicePageIndex !== -1 &&
          servicePageIndex !== wixCodeApi.location.path.length - 1;
        const serviceSlug = isServiceSlugExistsInPath
          ? wixCodeApi.location.path[servicePageIndex + 1]
          : '';
        const config = await httpClient.request(getConfig, {
          headers: { authorization: instance },
        })(serviceSlug, isPreview);

        navigateToCalendar = async () => {
          wixCodeApi.location.to &&
            wixCodeApi.location.to(config.serviceDto.fullUrl + '/book');
        };

        if (experiments.enabled('specs.bookings.SeoIntegration')) {
          await wixCodeApi.seo.renderSEOTags({
            itemType: 'BOOKINGS_SERVICE',
            itemData: config.SEO.serviceResponse,
            // seoData: userOverrides //JSON string from BO SEO panel
          });
        }

        if (!flowAPI.isSSR()) {
          getServiceSchedulingData(config, settings, httpClient, instance)
            .then((schedule) => {
              scheduleViewModel = schedulingSectionViewModelFactory({
                catalogSessionsDto: schedule?.sessions,
                businessInfo: config?.businessInfo,
                t,
              });
              setProps({
                scheduleViewModel,
              });
            })
            .catch((e) => {
              console.log('Getting schedules failed ', e);
              scheduleViewModel = {
                status: SchedulingSectionStatus.FAILED,
              };
              setProps({
                scheduleViewModel,
              });
            });
        }
        viewModel = servicePageViewModelFactory({
          config,
          t,
        });
      } else {
        const isEditorX =
          flowAPI.controllerConfig.config.style.styleParams.booleans.responsive;
        viewModel = dummyViewModelFactory({ t, isEditorX });
        const businessInfo = {
          timeZone: 'UTC',
          regionalSettingsLocale: 'en-US',
        };
        scheduleViewModel = dummySchedulingViewModel({
          t,
          businessInfo,
          scheduleDays: settings.scheduleDays,
        });
      }
      setProps({
        navigateToCalendar,
        scheduleViewModel,
        viewModel,
        isMobile,
        isRTL,
      });
    },
  };
};

export default createController;
