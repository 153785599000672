import { BodyViewModelBuilder } from './builders/BodyViewModel.builder';
import { WidgetConfig } from '../types/shared-types';
import {
  TitleAndTaglineViewModel,
  titleAndTaglineViewModelFactory,
} from './title-and-tagline-view-model/titleAndTaglineViewModel';
import {
  ContactSectionViewModel,
  contactSectionViewModelFactory,
} from './contact-section-view-model/contactSectionViewModel';
import {
  DetailsSectionViewModel,
  detailsSectionViewModelFactory,
} from './details-section-view-model/detailsSectionViewModel';
import {
  HeaderViewModel,
  headerViewModelFactory,
} from './header-view-model/headerViewModel';
import {
  DescriptionSectionViewModel,
  descriptionSectionViewModelFactory,
} from './description-section-view-modal/descriptionSectionViewModel';
import { HeaderViewModelBuilder } from './builders/HeaderViewModel.builder';
import { TitleAndTaglineViewModelBuilder } from './builders/TitleAndTaglineViewModel.builder';
import { DetailsSectionViewModelBuilder } from './builders/DetailsSectionViewModel.builder';
import { ContactSectionViewModelBuilder } from './builders/ContactSectionViewModel.builder';
import { DescriptionSectionViewModelBuilder } from './builders/DescriptionSectionViewModel.builder';
import { ConferencePlatform } from '@wix/bookings-uou-types/dist/src';
import {
  PolicySectionViewModel,
  policySectionViewModelFactory,
} from './policy-section-view-model/policySectionViewModel';
import { PolicySectionViewModelBuilder } from './builders/PolicySectionViewModel.builder';
import {
  BodyViewModel,
  bodyViewModelFactory,
} from './body-view-model/bodyViewModel';

export interface ServicePageViewModel {
  header: HeaderViewModel;
  body: BodyViewModel;
  titleAndTagline: TitleAndTaglineViewModel;
  detailsSection: DetailsSectionViewModel;
  contactSection: ContactSectionViewModel;
  descriptionSection: DescriptionSectionViewModel;
  policySection: PolicySectionViewModel;
}

export const servicePageViewModelFactory = ({
  config,
  t,
}: {
  config: WidgetConfig;
  t;
}): ServicePageViewModel => {
  const bookingPolicyDto = config?.bookingPolicyDto;
  const serviceDto = config?.serviceDto;
  const businessInfo = config?.businessInfo;
  const regionalSettingsLocale =
    businessInfo?.regionalSettingsLocale ?? 'en-US';
  const body = bodyViewModelFactory({ bookingPolicyDto, t });
  const isBookOnlineAllowed = body.isBookOnlineAllowed;

  return {
    header: headerViewModelFactory(serviceDto, isBookOnlineAllowed),
    body,
    titleAndTagline: titleAndTaglineViewModelFactory(serviceDto),
    detailsSection: detailsSectionViewModelFactory({
      serviceDto,
      regionalSettingsLocale,
      t,
      isBookOnlineAllowed,
    }),
    contactSection: contactSectionViewModelFactory(businessInfo),
    descriptionSection: descriptionSectionViewModelFactory(
      serviceDto,
      isBookOnlineAllowed,
    ),
    policySection: policySectionViewModelFactory(businessInfo),
  };
};

export const dummyViewModelFactory = ({
  t,
  isEditorX,
}): ServicePageViewModel => {
  const title = t('dummy-data.title');
  const tagline = t('dummy-data.tagline');
  const days = t('dummy-data.days');
  const duration = t('dummy-data.duration');
  const price = t('dummy-data.price');
  const location = t('dummy-data.location');
  const description = t('dummy-data.description');
  const policy = t('dummy-data.policy');
  const phone = t('dummy-data.phone');
  const email = t('dummy-data.email');
  const address = t('dummy-data.address');
  const imageUri = isEditorX
    ? '11062b_7f7efcdb410c48c2a65be450de809052~mv2.jpg'
    : '11062b_0cb0affb32094a8393eec1030d340a22~mv2.jpg';
  const isBookOnlineAllowed = true;

  return {
    header: new HeaderViewModelBuilder()
      .withImageUri(imageUri)
      .withTitle(title)
      .withBookOnlineAllowed(isBookOnlineAllowed)
      .build(),
    body: new BodyViewModelBuilder()
      .withBookOnlineAllowed(isBookOnlineAllowed)
      .build(),
    titleAndTagline: new TitleAndTaglineViewModelBuilder()
      .withTitle(title)
      .withTagline(tagline)
      .withOnlineProvider(ConferencePlatform.ZOOM)
      .build(),
    detailsSection: new DetailsSectionViewModelBuilder()
      .withDaysOffered(days)
      .withDuration(duration)
      .withPrice(price)
      .withLocation(location)
      .withBookOnlineAllowed(isBookOnlineAllowed)
      .build(),
    contactSection: new ContactSectionViewModelBuilder()
      .withPhone(phone)
      .withEmail(email)
      .withAddress(address)
      .build(),
    descriptionSection: new DescriptionSectionViewModelBuilder()
      .withDescription(description)
      .withBookOnlineAllowed(isBookOnlineAllowed)
      .build(),
    policySection: new PolicySectionViewModelBuilder()
      .withCancellationPolicy(policy)
      .build(),
  };
};
